<template>
  <main class="tw-relative tw-flex tw-bg-gray-900">
    <ClientOnly>
      <Notifications />
    </ClientOnly>

    <ClientOnly>
      <TourOnboarding />
    </ClientOnly>

    <ClientOnly>
      <section
        @mouseenter="isExpanded = true"
        class="tw-hidden tw-h-screen tw-overflow-y-scroll tw-p-5 tw-duration-700 tw-ease-in-out xl:tw-block"
        :class="[isExpanded ? 'tw-w-[350px]' : 'tw-w-max']"
      >
        <div class="tw-grid tw-h-full tw-content-between">
          <div class="tw-space-y-3">
            <div
              class="tw-flex tw-items-center tw-justify-between tw-py-3"
              :class="[isExpanded ? 'tw-pr-5' : '']"
            >
              <div>
                <AppLogo type="light" v-if="isExpanded" />
                <AppLogo
                  type="dark-round"
                  v-else
                  class="tw-m-auto tw-h-10 tw-w-10"
                />
              </div>

              <div :class="[isExpanded ? 'tw-block' : 'tw-hidden']">
                <Icon
                  @click.prevent="isExpanded = !isExpanded"
                  name="bi:layout-text-sidebar-reverse"
                  class="tw-h-6 tw-w-6 tw-cursor-pointer tw-text-gray-600"
                />
              </div>
            </div>

            <div>
              <DashboardSearch theme="dark" :isExpanded="isExpanded" />
            </div>

            <div class="tw-h-max">
              <ClientOnly>
                <DashboardSideMenu theme="dark" :isExpanded="isExpanded" />
              </ClientOnly>
            </div>
          </div>

          <div class="tw-h-max tw-divide-y tw-divide-gray-600">
            <div class="">
              <ClientOnly>
                <DashboardBottomSideMenu
                  theme="dark"
                  :isExpanded="isExpanded"
                />
              </ClientOnly>
            </div>
            <div class="tw-py-5">
              <ClientOnly>
                <DashboardBottomProfileMenu
                  theme="dark"
                  :isExpanded="isExpanded"
                />
              </ClientOnly>
            </div>
          </div>
        </div>
      </section>
    </ClientOnly>

    <section
      class="tw-h-screen tw-w-full tw-overflow-y-scroll tw-bg-white lg:tw-mt-2 lg:tw-rounded-tl-[38px]"
    >
      <ClientOnly>
        <DashboardMobileMenu />
      </ClientOnly>

      <div class="tw-h-screen tw-px-5 tw-py-8 md:tw-p-8 lg:tw-py-8">
        <slot />
      </div>
    </section>

    <PopupCreatePIN :setPinPrompt="store?.set_pin_prompt" />

    <DashboardSearchModal />

    <QuickActions />
  </main>
</template>

<script setup lang="ts">
import { useStore } from "~/store";
const isExpanded = ref<boolean>(true);
const store = useStore();
const { ABLY_API_KEY } = useRuntimeConfig().public;

const { $ably, $eventBus } = useNuxtApp();

if (ABLY_API_KEY && import.meta.client) {
  // Get & Subscribe To Ably Channel to Listen to events
  const channel = $ably.channels.get(`personal_${store?.active_school?.id}`);
  await channel.subscribe((message: any) => {
    $eventBus.$emit("refreshVirtualAccounts");
  });

  console.log(
    `Subscription to channel: ${`personal_${store?.active_school?.id}`} Successful!`,
  );
}
</script>

<style lang="scss" scoped></style>
